.matrixcontainer{
  position: absolute;
  display: flex;
  height: 200px;
  width: 100vw;
  align-items: center;
  /* margin-top: 70px; */
}
.img{
  position: absolute;
 width: 100vw;
  object-fit: cover;
}
.linkcontainer{
  display: flex;
  position: absolute;
  margin-top: 150px;
  width: 50vw;
  margin-left: 300px;
 
  
}
.links{
  font-family: 'First In Line';
  font-size: 30px;
  text-decoration: none;
  color: white;
  margin: 30px;
}
.icons{
  position: absolute;
  display: flex;
  height: 200px;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 80px;
  margin-left:1200px;
}
.icon{
  color: white;
  text-decoration: none;
  font-size: 40px;
}
