.maincontainer{
  display: flex;
  flex-direction: column;
  margin-top: 120px;
  height: 130vh;
 
}
.workline{
  display: flex;
  justify-content: flex-end;
  /* position: absolute; */
  border-bottom: 4px solid #65b99e;
  width: 20vw;
  margin-top: 70px;
}
.contact{
  width: 143px;
  height: 38px;
 margin-top: 50px;
  font-family: FirstInLine;
  font-size: 32px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 1.55px;
  text-align: justify;
  color: #65b99e;
}
.formtitle{
  color: white;
  font-family: FirstInLine;
  font-size: 36px;
  margin-top: 70px;
}
.footer{
  margin-top: 10px;
}
.linkcontainer{
  display: flex;
  position: absolute;
  margin-top: 150px;
  width: 50vw;
  margin-left: 300px;
 
  
}
.links{
  font-family: 'First In Line';
  font-size: 30px;
  text-decoration: none;
  color: white;
  margin: 30px;
}
.icons{
  position: absolute;
  display: flex;
  height: 200px;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 80px;
  margin-left:1200px;
}
.icon{
  color: white;
  text-decoration: none;
  font-size: 40px;
}