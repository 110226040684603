.form{
  display: flex;
  flex-direction: column;
  width: 50vw;
  margin-top: 30px;
  margin-left: 350px;
}
.input{
background-color: #454545;
margin: 20px;
padding: 20px;
border-radius: 11px;
}
::-webkit-input-placeholder { /* Chrome/Opera/Safari */
  color: white;
 font-size: 20px;
}
.button{
  position: absolute;
  width: 116px;
  height: 28px;
  padding: 8px 19px 1px 15px;
  border-radius: 11px;
  background-color: black;
  border: solid 1px #65b99e;
  margin-top: 490px;
  margin-left: 20px;
}
.button:hover{
  cursor:pointer;
}
.button-value{
  font-family: FirstInLine;
  color: #65b99e;
}
.buttontext{
  font-family: FirstInLine;
  color: #65b99e;
  margin: 0;
}