.maincontainer{
  display: flex;
  flex-direction: column;
  margin-top: 50vh;
  height: 300vh;
  position: relative;
}
.workline{
  display: flex;
  justify-content: flex-end;
  position: absolute;
  border-bottom: 4px solid #65b99e;
  width: 20vw;
  margin-top: 100px;
}
.mywork{
  width: 143px;
  height: 38px;
 margin-top: 50px;
  font-family: FirstInLine;
  font-size: 32px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 1.55px;
  text-align: justify;
  color: #65b99e;
}

.gameterest{
  display: flex;
  flex-direction: row;
}
.projectcontainer{
  display: flex;
  flex-direction: column;
  width: 40vw;
  margin-top: 300px;
  margin-left: 100px;
  
}
.titlecontainer{
  margin-bottom: 50px;
}
.title{
  color: white;
  position: absolute;
  font-family: FirstInLine;
  font-size: 36px;
  
}
.description{
  color: white;
  font-family: AppleSymbols;
  font-size: 24px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 1.03px;
  text-align: justify;

}
.rightcontainer{
  position: relative ;
  margin-left: 100px;
  margin-top: 300px;

}
.compcontainer{
  width: 420px;
}
.mainlink{
  display: flex;
  width: 420px;
  justify-content: flex-end;
}
.gameterestlink{
  color: #65b99e;
  font-family: FirstInLine;
  text-decoration: none;
  margin-bottom: 0;
  
}
.linkcontainer{
  border-bottom: 3px solid #65b99e;
   margin: 0 0;
  width: 150px;
}
.visit{
  margin: 0 0;
  font-size: 15px;
}
.toolscontainer{
  width: 100px;
  position: absolute;
  margin-left: 100px;
  margin-bottom: 50px;

}
.tools{
  color: white;
text-decoration: underline;
}
.imgcontainer{
  display: flex;
  flex-direction: row;
  width: 40px;

}
.league{
  margin: 0 0;
  font-size: 15px;
}
.myjournal{
  width: 400px;
}